<template>

    <div class="wrapper">
<!--         知识付费落地页-->


       <div class="top">
            <div class="left">
              <img src="https://static.yihu365.cn/img/wx-uni/logo_kts.png" alt="">
            </div>
            <div class="center">
                <div>医护到家</div>
                <div class="bo_t">下载医护到家，了解更多健康知识</div>
            </div>
            <div class="right" @click="download">
                  去下载
            </div>
       </div>
       <div class="f_height"></div>
      <div class="main">
         <div class="banner">
           <img :src="info.HEAD_PIC_URL" alt="">
         </div>
         <div class="bti">
              <div class="tit">{{ info.NAME }}</div>
              <div class="sub">共{{info.productItemList.length}}课时 ｜ {{info.SALE_NUM}}人学习 </div>
         </div>
        <div class="audio_box">
           <div class="a_tit">
              <span class="st">试听</span><span>{{info.productItemList[0].ITEM_NAME}}</span>
           </div>
<!--          <audio-->
<!--              src="https://static.yihu365.cn/img/h5Img/MustangFord.mp3"-->
<!--              controls-->
<!--              loop-->
<!--              @timeupdate="update()"-->
<!--          ></audio>-->
          <audio :src="info.productItemList[0].SERVICE_INFO_URL" ref="audioMedia"  @timeupdate="onTimeupdate"
                controls @canplay="onCanPlay">
          </audio>
           <div class="audio">
             <div class="slider">
               <span>{{audio.currentTime}}</span>
               <van-slider v-model="audio.sliderTime" @change="onChange" />
               <span>{{audio.maxTime}}</span>
             </div>
             <div class="play_box">
               <img @click="reduce" class="l_p" src="https://static.yihu365.cn/img/h5Img/after.png" alt="">
               <img @click='onPlay' class="c_p" :src="audio.playIcon" alt="">
               <img @click="add" class="r_p" src="https://static.yihu365.cn/img/h5Img/last.png" alt="">
             </div>
           </div>
        </div>
        <div class="kc_box">
          <van-tabs v-model:active="activeName">
            <van-tab title="课程介绍" name="a">
               <div class="jies">
                   <div class="b_ti">
                      <span class="k_u"></span>
                      <span>课程介绍</span>
                   </div>
                  <img v-for="(item,index) in info.INFO_URL" class="j_img" :src="item" alt="">
                 <div class="b_ti">
                   <span class="k_u"></span>
                   <span>课程目录</span>
                 </div>
                  <div class="ml_list">
                      <div class="list" v-for="(item,index) in info.productItemList">
                        <div class="txt">
                           <img v-if="index == 0" src="https://static.yihu365.cn/img/h5Img/suo_0.png" alt="">
                           <img v-else src="https://static.yihu365.cn/img/h5Img/suo.png" alt="">
                           <div>
                              <div class="bti2">{{item.ITEM_NAME}}</div>
                              <div>{{item.DURATION}}</div>
                           </div>
                        </div>
                        <van-divider />
                      </div>
                  </div>
               </div>
            </van-tab>
            <van-tab title="课程目录" name="b">
              <div class="jies">
                <div class="b_ti">
                  <span class="k_u"></span>
                  <span>课程目录</span>
                </div>
                <div class="ml_list">
                  <div class="list">
                    <div class="txt">
                      <img src="https://static.yihu365.cn/img/h5Img/suo.png" alt="">
                      <div>
                        <div class="bti2">月经推迟多久才能测试是否怀孕？月经推迟多久才能测试是否怀孕？</div>
                        <div>2分34秒</div>
                      </div>
                    </div>
                    <van-divider />
                  </div>
                  <div class="list">
                    <div class="txt">
                      <img src="https://static.yihu365.cn/img/h5Img/suo.png" alt="">
                      <div>
                        <div class="bti2">月经推迟多久才能测试是否怀孕？月经推迟多久才能测试是否怀孕？</div>
                        <div>2分34秒</div>
                      </div>
                    </div>
                    <van-divider />
                  </div>
                  <div class="list">
                    <div class="txt">
                      <img src="https://static.yihu365.cn/img/h5Img/suo.png" alt="">
                      <div>
                        <div class="bti2">月经推迟多久才能测试是否怀孕？月经推迟多久才能测试是否怀孕？</div>
                        <div>2分34秒</div>
                      </div>
                    </div>
                    <van-divider />
                  </div>
                </div>
              </div>
            </van-tab>

          </van-tabs>
        </div>
      </div>

      <div class="bottom_fix" @click="download">
        <div class="btn">
            打开医护到家 学习全套课程
        </div>
      </div>
    </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import homeApi from '@axios/home'
import { channel } from '@/config/channel.js';
export default {
  name: "knowledgePayment",
  setup(){

    const audio= ref({
      playing: false,
      // 音频当前播放时长
      currentTime: "00:00",
      // 音频最大播放时长
      maxTime: "00:00",
      minTime: 0,
      step: 0.1,
      play: false, // 播放暂停按钮
      sliderTime:0,//进度百分比
      playIcon:'https://static.yihu365.cn/img/h5Img/play.png'
    })

    const audioMedia = ref(null)


    const route = useRoute()
    const info = ref('')

    const getInfo = () => {
      const params ={
        productId : route.query.productId
      }
      homeApi.getActivityProductItemInfo(params).then((res)=> {

        console.log(res.data)
        info.value = res.data
        setWxShareDate(info.value.NAME,'https://static.yihu365.cn/img/wx-uni/logo_kts.png');// 分享
      })

    }
    getInfo();

    const onCanPlay = () => {
      audio.value.maxTime = realFormatSecond(audioMedia.value.duration)
    }
    // 开始播放
    const onPlay = ()=>{
      console.log(audioMedia.value.currentTime)
      console.log(audioMedia.value.duration)
      if(!audio.value.playing){
        audioMedia.value.play()
        audio.value.playing = true;
        audio.value.playIcon = "https://static.yihu365.cn/img/h5Img/pause.png"
      }else{
        audioMedia.value.pause()
        audio.value.playing = false;
        audio.value.playIcon = "https://static.yihu365.cn/img/h5Img/play.png"
      }
    }
    //播放时
    const onTimeupdate = (res) => {
      // console.log(res.timeStamp)
      audio.value.currentTime = realFormatSecond(audioMedia.value.currentTime)
      audio.value.sliderTime= (audioMedia.value.currentTime / audioMedia.value.duration) * 100

    }
    const reduce = () => {
      audioMedia.value.currentTime -= 3
    }
    const add = () => {

      audioMedia.value.currentTime += 3
    }
    const onChange = (data) => { //拖拽进度条
       const b = data / 100;
      audioMedia.value.currentTime = audioMedia.value.duration * b
    }
    // 将整数转换成 时：分：秒的格式
    const realFormatSecond=(second) =>{
      let secondType = typeof second;
      if (secondType === "number" || secondType === "string") {
        second = parseInt(second);
        let hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        let mimute = Math.floor(second / 60);
        second = second - mimute * 60;
        // hours + ':' +
        return ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
      } else {
        return "0:00:00";
      }
    }

    const download = () => {
       window.location.href = 'https://mi.yihu365.cn/download'
    }
    return {
      audio,
      onTimeupdate,
      onPlay,
      audioMedia,
      realFormatSecond,
      onCanPlay,
      onChange,
      reduce,
      add,
      info,
      download
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(.van-tabs__line){
  background-color:#FF9A9A;
}
::v-deep(.van-tab--active){
  color:#FF9A9A;
}
.wrapper{
  background: #F2F2F2;
  padding-bottom: 94px;
}
   .top{
     background: #FFF0F0;
     width: 100%;
     height:110px;
     display: flex;
     font-size: 26px;
     box-sizing: border-box;
     align-items: center;
     position: fixed;
     left: 0;
     top:0;
     z-index: 100;
     color:#EE5254;
     box-shadow:0px 15px 20px -12px #FFF0F0;
     .left{
       img{
         width: 70px;
         height: 70px;
         margin:0 28px;
       }

     }
     .center{
      font-weight: bold;
       white-space: nowrap;
       flex-grow: 1;
       .bo_t{
         font-size: 25px;
         font-weight: normal;
       }
     }
     .right{
       width:90px;
       font-weight: bold;
       font-size: 30px;
       padding:0 60px;
       text-align: center;
       color:#fff;
       line-height: 80px;
       border-radius: 40px;
       background: #FFA6A6;
       margin-right: 10px;
       white-space: nowrap;
     }
   }
   .f_height{
     width: 100%;
     height:110px;
   }
   .main{
     .banner{
      box-shadow:0px 20px 10px 0px rgba(0,0,0,0.5);
      img{
         width: 100%;
       }
     }
     .bti{
       background: #fff;
       box-sizing: border-box;
       padding:26px 40px;
       .tit{
         color:#434343;
         font-size: 36px;
         font-weight: bold;
       }
       .sub{
         color:#919191;
         font-size: 28px;
       }
     }
     .audio_box{
       padding:20px 0;
       width: 96%;
       margin:16px auto;
       background: #FFFFFF;
       border-radius: 14px;
       font-size: 26px;
       color:#434343;
       .audio{

         box-sizing: border-box;
         padding:10px 20px;
         color: #919191;
         .slider{
           display: flex;
           justify-content: space-between;
           align-items: center;
           ::v-deep(.van-slider__button){
             width: 22px;
             height: 22px;
             background: #FF9A9A;
           }
           ::v-deep(.van-slider__bar){
             background: #FF9A9A;
           }
           ::v-deep(.van-slider){
             width:480px;
             margin:0 15px;
           }
         }
         .play_box{

           display: flex;
           justify-content: center;
           align-items: center;
           margin-top: 22px;
           .l_p,.r_p{
             width: 40px;
             height: 40px;
           }
           .c_p{
             width: 100px;
             height: 100px;
             margin:0 90px;
           }
           .r_p{}
         }
       }

       audio{
         display: none;
         margin-left: 20px;
         margin-bottom: 20px;
       }
       .a_tit{
          box-sizing: border-box;
          padding:10px 10px 10px 20px;
         .st{
           background: #FF9A9A;
           border-radius: 18px;
           display: inline-block;
           color:#fff;
           padding:3px 8px;
           margin-right: 10px;
         }
       }
     }
     .kc_box{
       background: #FFFFFF;
       .jies{
         font-size: 0;
         .b_ti{
           padding:20px 30px;
           font-size: 28px;
           font-weight: bold;
           .k_u{
             display: inline-block;
             vertical-align: middle;
             width: 18px;
             height: 36px;
             background:#FF9A9A ;
             margin-right: 10px;
           }
         }
         .j_img{
           width: 100%;
           font-size: 0;
         }
         .ml_list{
           box-sizing: border-box;
           padding:10px 30px 0px;
           font-size: 24px;
           .list{
             .txt{
               color:#434343;
               display: flex;
               padding-left: 20px;
               box-sizing: border-box;
               .bti2{
                 font-size: 28px;
               }
             }
              img{
                width: 34px;
                height: 41px;
                margin-right: 20px;
              }
           }
           ::v-deep(.van-divider){
             margin:24px 0;
           }
         }
       }
     }

   }
.bottom_fix{
  position: fixed;
  left:0;
  bottom:0;
  z-index: 100;
  height:110px;
  width: 100%;
  background:#FFF0F0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  box-shadow:0px -15px 20px -12px #FFF0F0;
  .btn{
    color:#fff;
    background: #FFA6A6;
    padding: 16px 76px;
    border-radius: 30px;
  }
}
</style>
