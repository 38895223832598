import { render } from "./knowledgePayment.vue?vue&type=template&id=514f89a6&scoped=true"
import script from "./knowledgePayment.vue?vue&type=script&lang=js"
export * from "./knowledgePayment.vue?vue&type=script&lang=js"

import "./knowledgePayment.vue?vue&type=style&index=0&id=514f89a6&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-514f89a6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "514f89a6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('514f89a6', script)) {
    api.reload('514f89a6', script)
  }
  
  module.hot.accept("./knowledgePayment.vue?vue&type=template&id=514f89a6&scoped=true", () => {
    api.rerender('514f89a6', render)
  })

}

script.__file = "src/views/static/app/knowledgePayment.vue"

export default script